const debug = false;

export async function addToCart(title, value) {
    try {
        fbq('track', 'AddToCart', 
        // begin required parameter object
        {
          value: value,
          currency: 'PKR',
          content_ids: [title] // required property, if not using 'contents' property
        }
        // end required parameter object
        );
        if(debug)
        {
            console.log('add_to_cart',title)
        }
    } catch (error) {
        // ignore
    }
}

export async function removeFromCart(title) {
    try {
        // fbq('trackCustom', 'view_item', {item: title});
        if(debug)
        {
            console.log('remove_from_cart',title)
        }
    } catch (error) {
        // ignore   
    }
}

export async function viewItem(title) {
   try {
       fbq('trackCustom', 'view_item', {item: title});
     if(debug)
     {
         console.log('viewItem',title)
     }
   } catch (error) {
        // ignore
   }
}

export async function viewItemList(title) {
    try {
        fbq('trackCustom', 'view_item_list', {item: title})
        if(debug)
        {
            console.log('view_item_list',title)
        }
    } catch (error) {
        // ignore
    }
}

export async function beginCheckout(value, items) {
    try {
        fbq('track', 'InitiateCheckout', {contents: items, currency: 'PKR', num_items: items.length, value: value})
        if(debug)
        {
            console.log('begin_checkout')
        }
    } catch (error) {
        // ignore
    }
}

export async function purchase(purchaseId, value, items) {
    try {
        fbq('track', 'Purchase', {currency: "PKR", value: value, transaction_id: purchaseId, contents: items, content_type: 'product'});
        if(debug)
        {
            console.log('purchase', {
            'transaction_id': purchaseId,
            'value': value,
            'items': items
            })
        }
    } catch (error) {
        // ignore
    }
}
