<template>
  <div
    :class="`cartmini__area tp-all-font-roboto ${
      cartStore.cartOffcanvas ? 'cartmini-opened' : ''
    }`"
  >
    <div class="cartmini__wrapper d-flex justify-content-between flex-column">
      <div class="cartmini__top-wrapper">
        <div class="cartmini__top p-relative">
          <div class="cartmini__top-title">
            <h4>Shopping cart</h4>
          </div>
          <div class="cartmini__close">
            <button
              @click="cartStore.handleCartOffcanvas"
              type="button"
              class="cartmini__close-btn cartmini-close-btn"
            >
              <i class="fal fa-times"></i>
            </button>
          </div>
        </div>
        <div class="cartmini__shipping">
          <CartProgress />
        </div>
        <div v-if="cartStore.cart_products.length > 0" class="cartmini__widget">
          <div
            v-for="item in cartStore.cart_products"
            :key="item.name"
            class="cartmini__widget-item"
          >
            <div class="cartmini__thumb">
              <router-link :to="`/${item.route}`">
                <img :src="item.website_image" alt="cart-img" width="70" height="60" />
              </router-link>
            </div>
            <div class="cartmini__content">
              <h5 class="cartmini__title">
                <router-link :to="`/${item.route}`">
                  {{ item.web_item_name }}
                </router-link>
                <span class="cartmini__quantity">{{ " " }}x{{ item.orderQuantity }}</span>
              </h5>
              <div class="cartmini__price-wrapper">
                <span
                  v-if="item.discount > 0 && item.orderQuantity"
                  class="cartmini__price"
                >
                  Rs {{
                    (
                      Number(item.price_list_rate) *
                      item.orderQuantity
                    ).toFixed(2)
                  }}
                </span>
                <span v-else class="cartmini__price">
                  Rs {{item.orderQuantity && (item.price_list_rate * item.orderQuantity).toFixed(2)}}
                </span>
              </div>
            </div>
            <a
              @click="cartStore.removeCartProduct(item)"
              class="cartmini__del cursor-pointer">
              <i class="fa-regular fa-xmark"></i>
            </a>
          </div>
        </div>
        <!-- if no item in cart  -->
        <div
          v-if="cartStore.cart_products.length === 0"
          class="cartmini__empty text-center"
        >
          <img
            src="@/assets/img/product/cartmini/empty-cart.png"
            alt="empty-cart-img"
          />
          <p>Your Cart is empty</p>
          <router-link to="/shop" class="tp-btn">Go to Shop</router-link>
        </div>
      </div>
      <div v-if="cartStore.cart_products.length > 0" class="cartmini__checkout">
        <div class="cartmini__checkout-title mb-30">
          <h4>Subtotal:</h4>
          <span>Rs {{ cartStore.totalPriceQuantity.total.toFixed(2) }}</span>
        </div>
        <div class="cartmini__checkout-btn">
          <router-link
            to="/cart"
            @click="cartStore.handleCartOffcanvas"
            class="tp-btn mb-10 w-100"
          >
            view cart
          </router-link>
          <router-link
            to="/checkout"
            @click="cartStore.handleCartOffcanvas"
            class="tp-btn tp-btn-border w-100"
          >
            checkout
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <!-- overlay start  -->
  <div
    @click="cartStore.handleCartOffcanvas"
    :class="`body-overlay ${cartStore.cartOffcanvas ? 'opened' : ''}`"
  ></div>
  <!-- overlay end  -->
</template>

<script setup lang="ts">
import { useCartStore } from '@/stores/pinia/useCartStore';
import CartProgress from '@/components/Cart/CartProgress.vue';

const cartStore = useCartStore();
</script>
