<template>
    <header>
      <div class="tp-header-area p-relative z-index-11">
        <!-- header top start  -->
        <div class="tp-header-top black-bg p-relative z-index-1 d-none d-md-block">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-md-12 justify-center">
                <div class="tp-header-welcome d-flex justify-content-center py-1">
                  <span>
                    <SvgShippingCar />
                  </span>
                  <p>FREE Express Shipping On Orders Rs2000 and above+</p>
                </div>
              </div>
              <!-- <div class="col-md-6">
                <div class="tp-header-top-right d-flex align-items-center justify-content-end">
                  <header-component-top-menu />
                </div>
              </div> -->
            </div>
          </div>
        </div>
  
        <!-- header main start -->
        <div class="tp-header-main tp-header-sticky">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-xl-2 col-lg-2 col-md-4 col-6">
                <div class="logo">
                  <router-link to="/">
                    <img src="@/assets/img/logo/SofoMart.png" alt="logo" height="35" class="logo-img" />
                  </router-link>
                </div>
              </div>
              <div class="col-xl-6 col-lg-7 d-none d-lg-block">
                <!-- search form start -->
                <!-- <HeaderSearch /> -->
                <!-- search form end -->
              </div>
              <div class="col-xl-4 col-lg-3 col-md-8 col-6">
                <!-- header right start -->
                <HeaderComponentMainRight />
                <!-- header right end -->
              </div>
            </div>
          </div>
        </div>
  
        <!-- header bottom start -->
        <div class="tp-header-bottom tp-header-bottom-border d-none d-lg-block">
          <div class="container">
            <div class="tp-mega-menu-wrapper p-relative">
              <div class="row align-items-center">
                <!-- <div class="col-xl-3 col-lg-3"> -->
                  <!-- categories start -->
                  <!-- <HeaderComponentTopCategories /> -->
                  <!-- categories end -->
                <!-- </div> -->
                <!-- <div class="col-xl-6 col-lg-6"> -->
                <div class="col-xl-9 col-lg-9">
                  <div class="main-menu menu-style-1">
                    <nav class="tp-main-menu-content">
                      <!-- menus start -->
                      <HeaderComponentMenus />
                      <!-- menus end -->
                    </nav>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-3">
                  <div class="tp-header-contact d-flex align-items-center justify-content-end">
                    <div class="tp-header-contact-icon">
                      <span>
                        <SvgContact />
                      </span>
                    </div>
                    <div class="tp-header-contact-content">
                      <h5>Hotline:</h5>
                      <p><a href="tel:+923399007636">+92 (339) 900-7636</a></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <!-- header sticky start -->
      <div id="header-sticky-2" :class="`tp-header-sticky-area ${isSticky ? 'header-sticky-2' : ''}`">
        <div class="container">
          <div class="tp-mega-menu-wrapper p-relative">
            <div class="row align-items-center">
              <div class="col-xl-3 col-lg-3 col-md-3 col-6">
                <div class="logo">
                  <router-link to="/">
                    <img src="@/assets/img/logo/SofoMart.png" alt="logo" class="logo-img" height="35">
                  </router-link>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 d-none d-lg-block">
                <div class="tp-header-sticky-menu main-menu menu-style-1">
                  <nav id="mobile-menu">
                    <!-- menus start -->
                    <HeaderComponentMenus />
                    <!-- menus end -->
                  </nav>
                </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-6">
                <div class="tp-header-action d-flex align-items-center justify-content-end ml-50">
                  <div class="tp-header-action-item d-none d-lg-block">
                    <router-link to="/compare" class="tp-header-action-btn">
                      <SvgCompare />
                    </router-link>
                  </div>
                  <div class="tp-header-action-item d-none d-lg-block">
                    <router-link to="/wishlist" class="tp-header-action-btn">
                      <SvgWishlist />
                      <span class="tp-header-action-badge">{{wishlistStore.wishlists.length}}</span>
                    </router-link>
                  </div>
                  <div class="tp-header-action-item">
                    <button @click="cartStore.handleCartOffcanvas" type="button" class="tp-header-action-btn cartmini-open-btn">
                      <SvgCartBag />
                      <span class="tp-header-action-badge">{{ cartStore.totalPriceQuantity.quantity }}</span>
                    </button>
                  </div>
                  <div class="tp-header-action-item d-lg-none">
                    <button @click="utilsStore.handleOpenMobileMenu()" type="button" class="tp-header-action-btn tp-offcanvas-open-btn">
                      <SvgMenuIcon />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- header sticky end -->
    </header>
    <!-- cart offcanvas start -->
    <OffcanvasCartSidebar/>
    <!-- cart offcanvas end -->
  
    <!-- cart offcanvas start -->
    <OffcanvasMobileSidebar product-type="electronics"/>
    <!-- cart offcanvas end -->
  </template>
  
<script setup>
import { useCartStore } from '@/stores/pinia/useCartStore'
import { useWishlistStore } from '@/stores/pinia/useWishlistStore'
import { useUtilityStore} from '@/stores/pinia/useUtilityStore'
import { useSticky } from '@/utils'
import HeaderSearch from '@/components/Header/HeaderSearch.vue'
import HeaderComponentMainRight from '@/components/Header/HeaderComponentMainRight.vue'
import HeaderComponentTopCategories from '@/components/Header/TopCategories.vue'
import OffcanvasCartSidebar from '@/components/Offcanvas/OffcanvasCartSidebar.vue'
import OffcanvasMobileSidebar from '@/components/Offcanvas/OffcanvasMobileSidebar.vue'
import HeaderComponentMenus from '@/components/Header/Menus.vue'
import SvgShippingCar from '@/components/Icons/svg/shipping-car.vue'
import SvgContact from '@/components/Icons/svg/contact.vue'
import SvgCompare from "@/components/Icons/svg/compare.vue";
import SvgWishlist from "@/components/Icons/svg/wishlist.vue";
import SvgCartBag from "@/components/Icons/svg/cart-bag.vue";
import SvgMenuIcon from "@/components/Icons/svg/menu-icon.vue";

const { isSticky } = useSticky()
const cartStore = useCartStore()
const wishlistStore = useWishlistStore()
const utilsStore = useUtilityStore()
</script>
<style>
.logo-img {
  filter: drop-shadow(#000 1px 1px 0px);
}
</style>
  