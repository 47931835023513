<template>
    <div
      class="tp-header-main-right d-flex align-items-center justify-content-end"
    >
      <div class="tp-header-login d-none d-lg-block">
        <router-link to="/account" class="d-flex align-items-center">
          <div class="tp-header-login-icon">
            <span>
              <SvgUser />
            </span>
          </div>
          <div class="tp-header-login-content d-none d-xl-block">
            <span>Hello, {{ session.isLoggedIn ? session.user.first_name : 'Sign In' }}</span>
            <h5 class="tp-header-login-title">Your Account</h5>
          </div>
        </router-link>
      </div>
      <div class="tp-header-action d-flex align-items-center ml-50">
        <div class="tp-header-action-item d-none d-lg-block">
          <router-link to="/compare" class="tp-header-action-btn">
            <SvgCompare />
          </router-link>
        </div>
        <div class="tp-header-action-item d-none d-lg-block">
          <router-link to="/wishlist" class="tp-header-action-btn">
            <SvgWishlist />
            <span class="tp-header-action-badge">{{wishlistStore.wishlists.length}}</span>
          </router-link>
        </div>
        <div class="tp-header-action-item">
          <button @click="cartStore.handleCartOffcanvas" type="button" class="tp-header-action-btn cartmini-open-btn">
            <SvgCartBag />
            <span class="tp-header-action-badge">{{ cartStore.cart_products.length }}</span>
          </button>
        </div>
        <div class="tp-header-action-item d-lg-none">
          <button
            @click="utilsStore.handleOpenMobileMenu"
            type="button"
            class="tp-header-action-btn tp-offcanvas-open-btn"
          >
            <SvgMenuIcon />
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script setup lang="ts">
  import { useCartStore } from '@/stores/pinia/useCartStore';
  import { useWishlistStore } from '@/stores/pinia/useWishlistStore';
  import { useUtilityStore} from '@/stores/pinia/useUtilityStore';
  import SvgUser from "@/components/Icons/svg/user.vue";
  import SvgCompare from "@/components/Icons/svg/compare.vue";
  import SvgWishlist from "@/components/Icons/svg/wishlist.vue";
  import SvgCartBag from "@/components/Icons/svg/cart-bag.vue";
  import SvgMenuIcon from "@/components/Icons/svg/menu-icon.vue";
  import sessionStore from '@/stores/sessionStore'
  
  const cartStore = useCartStore();
  const wishlistStore = useWishlistStore();
  const utilsStore = useUtilityStore();
  const session = sessionStore();
  </script>
  