import sessionStore from '@/stores/sessionStore'
import { createRouter, createWebHistory, isNavigationFailure } from 'vue-router'
// import settingsStore from './stores/settingsStore'

const routes = [
	{
		path: '/login',
		name: 'Login',
		component: () => import('@/pages/Login.vue'),
		meta: {
			hideSidebar: true,
			isGuestView: true,
		},
	},
	{
		path: '/forgot',
		name: 'Forgot',
		component: () => import('@/pages/Forgot.vue'),
		meta: {
			hideSidebar: true,
			isGuestView: true,
		},
	},
	{
		path: '/register',
		name: 'Register',
		component: () => import('@/pages/Register.vue'),
		meta: {
			hideSidebar: true,
			isGuestView: true,
		},
	},
	{
		path: '/',
		name: 'Home',
		component: () => import('@/home/Home.vue'),
		meta: {
			hideSidebar: true,
			isGuestView: true,
		},
	},
	{
		path: '/account',
		name: 'Profile',
		component: () => import('@/pages/Profile.vue'),
		meta: {
			hideSidebar: true,
			isGuestView: false,
		},
	},
	{
		path: '/order-details',
		name: 'Order',
		component: () => import('@/pages/Order.vue'),
		meta: {
			hideSidebar: true,
			isGuestView: true,
		},
	},
	{
		path: '/terms-and-conditions',
		name: 'Terms',
		component: () => import('@/pages/Terms.vue'),
		meta: {
			hideSidebar: true,
			isGuestView: true,
		},
	},
	{
		path: '/return-policy',
		name: 'Returns',
		component: () => import('@/pages/Returns.vue'),
		meta: {
			hideSidebar: true,
			isGuestView: true,
		},
	},
	{
		path: '/privacy-policy',
		name: 'Privacy',
		component: () => import('@/pages/Privacy.vue'),
		meta: {
			hideSidebar: true,
			isGuestView: true,
		},
	},
	{
		path: '/cookie-policy',
		name: 'Cookies',
		component: () => import('@/pages/Cookies.vue'),
		meta: {
			hideSidebar: true,
			isGuestView: true,
		},
	},
	{
		path: '/wishlist',
		name: 'Wishlist',
		component: () => import('@/pages/Wishlist.vue'),
		meta: {
			hideSidebar: true,
			isGuestView: true,
		},
	},
	{
		path: '/compare',
		name: 'Compare',
		component: () => import('@/pages/Compare.vue'),
		meta: {
			hideSidebar: true,
			isGuestView: true,
		},
	},
	{
		path: '/checkout',
		name: 'Checkout',
		component: () => import('@/pages/Checkout.vue'),
		meta: {
			hideSidebar: true,
			isGuestView: true,
		},
	},
	{
		path: '/cart',
		name: 'Cart',
		component: () => import('@/pages/Cart.vue'),
		meta: {
			hideSidebar: true,
			isGuestView: true,
		},
	},
	{
		path: '/contact',
		name: 'Contact',
		component: () => import('@/pages/Contact.vue'),
		meta: {
			hideSidebar: true,
			isGuestView: true,
		},
	},
	{
		path: '/shop',
		name: 'Shop',
		component: () => import('@/pages/Category.vue'),
		meta: {
			hideSidebar: true,
			isGuestView: true,
		},
	},
	{
		path: '/deals',
		name: 'Deals',
		component: () => import('@/pages/Deals.vue'),
		meta: {
			hideSidebar: true,
			isGuestView: true,
		},
	},
	{
		path: '/sales-and-offers',
		name: 'SaleItems',
		component: () => import('@/pages/Sale.vue'),
		meta: {
			hideSidebar: true,
			isGuestView: true,
		},
	},
	{
		path: '/shop-categories',
		name: 'ShopCategories',
		component: () => import('@/pages/Categories.vue'),
		meta: {
			hideSidebar: true,
			isGuestView: true,
		},
	},
	{
		path: '/product-details/:id',
		name: 'ProductDetail',
		component: () => import('@/pages/ProductDetails.vue'),
		meta: {
			hideSidebar: true,
			isGuestView: true,
		},
	},
	// {
	// 	path: '/search',
	// 	name: 'Search',
	// 	component: () => import('@/pages/Profile.vue'),
	// 	meta: {
	// 		hideSidebar: true,
	// 		isGuestView: true,
	// 	},
	// },
	{
		path: '/no-permission',
		name: 'NoPermission',
		component: () => import('@/pages/NoPermission.vue'),
		meta: {
			hideSidebar: true,
			isGuestView: true,
		},
	},
	{
		path: '/not-found',
		name: 'NotFound',
		component: () => import('@/pages/NotFound.vue'),
		meta: {
			hideSidebar: true,
			isGuestView: true,
		},
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'GetDynamicRoute',
		component: () => import('@/pages/GetDynamicRoute.vue'),
		meta: {
			hideSidebar: true,
			isGuestView: true,
		},
	},
]

let router = createRouter({
	history: createWebHistory('/'),
	routes,
})

// function sleep(ms: number) {
// 	return new Promise(resolve => setTimeout(resolve, ms));
// }

router.beforeEach(async (to, _, next) => {
	const session = sessionStore()
	!session.initialized && (await session.initialize())

	// await sleep(5000)
	if (to.meta.isGuestView && !session.isLoggedIn && to.name !== 'Login') {
		// if page is allowed for guest, and is not login page, allow
		return next()
	}
	// if( to.name === 'Login' && !session.isLoggedIn)
	// {
	// 	console.log('is login')
	// 	return next()
	// }

	// route to login page if not logged in
	if (!session.isLoggedIn) {
		// if in dev mode, open login page
		// if (import.meta.env.DEV) {
			// console.log('meta env')
			return to.fullPath === '/login' ? next() : next('/login')
		// }
		// redirect to frappe login page, for oauth and signup
		// console.log('window.location.href')
		// window.location.href = '/login'
		// return next(false)
	}

	if (!session.isAuthorized && to.name !== 'No Permission') {
		return next('/no-permission')
	}
	if (session.isAuthorized && to.name === 'No Permission') {
		return next()
	}
	if (to.meta.isAllowed && !to.meta.isAllowed()) {
		return next('/no-permission')
	}

	// const settings = settingsStore()
	// if (!settings.initialized) {
	// 	await settings.initialize()
	// }
	// console.log('else next')
	to.path === '/login' ? next('/') : next()
})

router.afterEach((to, from, failure) => {
	window.scrollTo({ top: 0, behavior: "smooth" });
	// const TRACKED_RECORDS = ['Dashboard', 'NotebookPage']
	// const TRACKED_RECORDS = ['Dashboard']
	// const toName = to.name as string
	// if (
	// 	TRACKED_RECORDS.includes(toName) &&
	// 	toName !== from.name &&
	// 	to.params.name !== from.params.name
	// ) {
	// 	sessionStore().createViewLog(toName, to.params.name as string)
	// }
})

// const _fetch = window.fetch
// window.fetch = async function () {
// 	const res = await _fetch(...arguments)
// 	if (res.status === 403 && (!document.cookie || document.cookie.includes('user_id=Guest'))) {
// 		sessionStore().resetSession()
// 		router.push('/login')
// 	}
// 	return res
// }

export default router
